import React, { useState } from "react";
import { Link } from "react-router-dom";
import UserForm from "../useless/UserForm";
import UserProfile from "../userProfile/UserProfile";
import { img_url } from "../../config";

import "./sidebar.css";


/*
|========================================================
| SIDEBAR COMPONENT - MIAN COMPONENT
|========================================================
*/
function Sidebar({ title, pic }) {

  // get login user detail from local storage
  let user = JSON.parse(localStorage.getItem("user"));
  // console.log(user);

  if (pic) {
    console.log("yes");
  }



  return (
    <>
      {user.role_id == 2 ?

        <div className="profile-tab-nav border">

          <div className="p-4">
            <div className="img-circle text-center mb-3 px-2">
              <div className="img-circle text-center mb-3">
                {user.profile_image ?
                  <img className="nav-logo" src={img_url + "storage/images/users/sm/" + user.profile_image} alt="main logo" />
                  :
                  <img className="nav-logo" src={require('../../../src/assets/placeholders/profie.jpg')} alt="main logo" />
                }
              </div>
            </div>
            <Link className="nav-link"
              id="account-tab"
              data-toggle="pill"
              to="/tutor/profile"
              role="tab"
              aria-controls="account"
              aria-selected="true">
              <h5 className="text-center">{user.name}</h5>
              <p className="text-center"><small>(Tutor) </small></p>
            </Link>
          </div>

          <div
            className="nav flex-column nav-pills px-3 py-0"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <Link
              className={`nav-link ${title == "profile" ? "active-tab" : ""}`}
              id="account-tab"
              data-toggle="pill"
              to="/tutor/profile"
              role="tab"
              aria-controls="account"
              aria-selected="true"
            >
              <i className="fa fa-home text-center mr-1"></i>
              Profile
            </Link>
            <Link
              className={`nav-link ${title == "services" ? "active-tab" : ""}`}
              id="account-tab"
              data-toggle="pill"
              to="/tutor/services"
              role="tab"
              aria-controls="account"
              aria-selected="true"
            >
              <i className="fa fa-home text-center mr-1"></i>
              Services
            </Link>
            <Link
              className={`nav-link ${title == "offers" ? "active-tab" : ""}`}
              id="password-tab"
              data-toggle="pill"
              to="/tutor/offers"
              role="tab"
              aria-controls="password"
              aria-selected="false"
            >
              <i className="fa fa-graduation-cap mr-1"></i>
              Offers
            </Link>
            <Link
              className={`nav-link ${title == "subjects" ? "active-tab" : ""}`}
              id="password-tab"
              data-toggle="pill"
              to="/tutor/subjects"
              role="tab"
              aria-controls="password"
              aria-selected="false"
            >
              <i className="fa-solid fa fa-book"></i>
              Subjects
            </Link>
            <Link
              className={`nav-link ${title == "password" ? "active-tab" : ""}`}
              id="password-tab"
              data-toggle="pill"
              to="/tutor/password/update"
              role="tab"
              aria-controls="password"
              aria-selected="false"
            >
              <i className="fa fa-key text-center mr-1"></i>
              Security
            </Link>
            <Link
              className="nav-link"
              id="security-tab"
              data-toggle="pill"
              to="/logout"
              role="tab"
              aria-controls="security"
              aria-selected="false"
            >
              <i className="fa fa-power-off text-center mr-1"></i>
              Log Out
            </Link>
          </div>
        </div>

        :
        <div className="profile-tab-nav border">

          <div className="p-4">
            <div className="img-circle text-center mb-3 px-2">
              <div className="img-circle text-center mb-3">
                {user.profile_image ?
                  <img className="nav-logo" key={user.profile_image} src={img_url + "storage/images/users/sm/" + user.profile_image} alt="main logo" />
                  :
                  <img className="nav-logo" src={require('../../../src/assets/placeholders/profie.jpg')} alt="main logo" />
                }
              </div>
            </div>
            <Link className="nav-link"
              id="account-tab"
              data-toggle="pill"
              to="/student/profile"
              role="tab"
              aria-controls="account"
              aria-selected="true">
              <h5 className="text-center">{user.name}</h5>
              <p className="text-center"><small>(Student)</small></p>
            </Link>
          </div>

          <div
            className="nav flex-column nav-pills px-3 py-0"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <Link
              className={`nav-link ${title == "profile" ? "active-tab" : ""}`}
              id="account-tab"
              data-toggle="pill"
              to="/student/profile"
              role="tab"
              aria-controls="account"
              aria-selected="true"
            >
              <i className="fa fa-home text-center mr-1"></i>
              Profile
            </Link>
            <Link
              className={`nav-link ${title == "services" ? "active-tab" : ""}`}
              id="password-tab"
              data-toggle="pill"
              to="/student/services"
              role="tab"
              aria-controls="password"
              aria-selected="false"
            >
              <i className="fa fa-graduation-cap mr-1"></i>
              Services
            </Link>
            <Link
              className={`nav-link ${title == "add-service" ? "active-tab" : ""}`}
              id="password-tab"
              data-toggle="pill"
              to="/student/service/create"
              role="tab"
              aria-controls="password"
              aria-selected="false"
            >
              <i className="fa-solid fa fa-plus"></i>
              Add New Service
            </Link>
            <Link
              className={`nav-link ${title == "password" ? "active-tab" : ""}`}
              id="password-tab"
              data-toggle="pill"
              to="/student/password/update"
              role="tab"
              aria-controls="password"
              aria-selected="false"
            >
              <i className="fa fa-key text-center mr-1"></i>
              Security
            </Link>
            <Link
              className="nav-link"
              id="security-tab"
              data-toggle="pill"
              to="/logout"
              role="tab"
              aria-controls="security"
              aria-selected="false"
            >
              <i className="fa fa-power-off text-center mr-1"></i>
              Log Out
            </Link>
          </div>
        </div>
      }
    </>
  );
}

export default Sidebar;
