import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import Sidebar from "../sidebar/Sidebar";
import { Link } from "react-router-dom";
import { api_url, img_url } from "../../config";




/*
|========================================================
| SERVICE DETAIL - MIAN COMPONENT
|========================================================
*/
function Feedback() {

  // USE USE-STATE HOOKS TO MANAGE PAGE CONTENT
  const [service, setService] = useState(" ");
  const [selecteApplilcant, setSelectedApplicant] = useState(" ");
  // const [serviceFeedback, setServiceFeedback] = useState(" ");
  // const [serviceId, setServiceId] = useState(" ");
  // const [applicantId, setApplicantId] = useState("");
  const [rating, setRating] = useState(" ");
  const [feedback, setFeedback] = useState(" ");
  const [offer, setOffer] = useState(" ");
  const [comment, setComment] = useState(" ");
  const [preloader, setPreloader] = useState(true);
  const [validationErrors, setValidationErros] = useState(false);
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState(false);
  const [message, setMessage] = useState("");

  // useNavigate hook for redirect after login
  const navigate = useNavigate();

  // useLocation hook to get url complete detail
  const location = useLocation();
  const path = location.pathname;
  // console.log(path);

  // useParam hook to get params from url
  const params = useParams();
  const serviceId = params.id;
  const applicantId = params.iid;

  // setServiceId(service_id);
  // setApplicantId(applicant_id);


  // console.log(serviceId);

  const token = localStorage.getItem("token");
  let user = JSON.parse(localStorage.getItem("user"));
  // console.log(user);

  useEffect(() => {
    getStudentServiceDetail();
  }, [])



  /*
  |========================================================
  | GET STUDENT SERVICE DETAIL API CALLING
  |========================================================
  */
  async function getStudentServiceDetail(e) {

    let url = api_url + "user/service/" + serviceId + "/edit";
    // console.log(url);

    const response = await axios.get(
      url,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }
    );

    // console.log(response);
    if (response && response.status == 200) {

      setPreloader(false);

      let user = JSON.parse(localStorage.getItem("user"));
      // console.log(user);

      let service = response.data.service;
      let selectedApplicant = response.data.selected_applicant;

      if (service.feedback) {
        if (user.role_id == 2) {
          setRating(service.feedback.student_ratings);
          setFeedback(service.feedback.student_feedback);
        } else {
          setRating(service.feedback.tutor_ratings);
          setFeedback(service.feedback.tutor_feedback);
        }
      }

      setService(service);
      setSelectedApplicant(selectedApplicant);
      // console.log(service);
    }
    else {
      console.log("API FAILED");
    }

  }


  /*
  |========================================================
  | SEND OFFER ON THIS SERVICE -- API CALLING
  |========================================================
  */
  async function sendFeedback(e) {
    e.preventDefault();

    const token = localStorage.getItem("token");
    // console.log(token);

    // make formData for calling send offer api
    const formData = new FormData();
    formData.append("rating", rating);
    formData.append("feedback", feedback);
    const body = formData;

    //  API post method
    let url = api_url + "user/service/" + serviceId + "/feedback";
    // console.log(url);

    const response = await axios.post(
      url,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }
    );

    if (response && response.status == 200) {
      setPreloader(false);

      const data = response.data;
      if (data.status == 200) {
        let message = data.message;
        setMessage(message);
        setSuccessMessage(true);

      } else {
        setErrors(data.errors);
        setValidationErros(true);
      }

    } else {
      console.log("API CALLING FAILED");
    }

  }


  return (
    <>
      {
        !preloader ?
          <div className="container py-5">
            <div className="row">

              <div className="col-md-3">
                <Sidebar title="service-detail" />
              </div>

              <div className="col-md-9">
                <div className="row g-0">

                  <h3 className="card-heading">Feedback
                    <Link to="/student/services" style={{ float: "right" }}>
                      <button className="btn btn-dark">BACK</button>
                    </Link>
                  </h3>

                  <div className="col-md-12 px-0">
                    <div className="card mb-3">

                      <div className="card-header pb-2 border-0 offer-card-bg">
                        <h6 className="text-muted">SERVICE</h6>
                        <h3 className="card-title mb-0 text-primary-cust"><strong>{service.title}</strong></h3>
                        <h4 className="card-title">Budget: {service.budget} PKR</h4>
                      </div>

                      <div className="card-body">
                        <p>{service.description}</p>
                        <div className="row">
                          <div className="col-3">
                            <p className="mb-0">Subject</p>
                            <b> {service.subject.title}</b>
                          </div>
                          <div className="col-3">
                            <p className="mb-0">Want To Read</p>
                            <b> {service.want_to_learn}</b>
                          </div>
                          <div className="col-3">
                            <p className="mb-0">Status</p>
                            <b> {service.status}</b>
                          </div>
                          {/* <div className="col-3">
                            <p className="mb-0">Total Applicants</p>
                            <b> {service.applicants_count}</b>
                          </div> */}
                        </div>
                      </div>

                      {user.role_id == 2 || user.role_id == 3 ?
                        <>
                          <hr />
                          <div className="card-body pt-3">
                            <h4 className="text-info mb-5">Feedback / Review</h4>
                            <form onSubmit={sendFeedback}>
                              <div className="row">

                                <div className="col-md-4 mb-3 ">
                                  {selecteApplilcant.user.profile_image ?
                                    <img className="d-flex mx-auto rounded-circle" width={120} height={120} src={img_url + "storage/images/users/sm/" + selecteApplilcant.user.profile_image} alt="main logo" />
                                    :
                                    <img width={120} height={120} src={require('../../../src/assets/placeholders/profie.jpg')} alt="main logo" />
                                  }

                                  {/* <img className="d-flex mx-auto" width={120} height={120} src={require('../../../src/assets/placeholders/profie.jpg')} alt="main logo" /> */}
                                  <h6 className="text-center mb-0">{selecteApplilcant.user.name}</h6>
                                  <h6 className="text-center mb-0">{selecteApplilcant.user.email}</h6>
                                  <h6 className="text-center mb-0">{selecteApplilcant.user.country_code}  {selecteApplilcant.user.mobile_no}</h6>
                                  <h3 className="text-center"><strong>{selecteApplilcant.offer} PKR</strong></h3>
                                </div>

                                <div className="col-md-1">
                                </div>

                                <div className="col-md-7">

                                  {
                                    validationErrors ?
                                      <div className="alert alert-danger alert-dismissible" role="alert">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                        <ul className="mb-0">
                                          {errors.map((error) => {
                                            return (
                                              <li key={error.id} className='list'>
                                                <span className='repo-text'>{error} </span>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </div>
                                      :
                                      <></>
                                  }

                                  {
                                    message ?
                                      <div className="alert alert-success alert-dismissible" role="alert">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                        <h6 className="mb-0">{message}</h6>
                                      </div>
                                      :
                                      <></>
                                  }

                                  <div className="col-md-12 mb-3 px-2">
                                    <div className="input-cust-div">
                                      <label htmlFor="rating" className="form-label">
                                        <strong>Rate Your Tutor</strong> (required)
                                      </label>
                                      <select
                                        id="rating"
                                        name="rating"
                                        className="form-select"
                                        value={rating}
                                        required
                                        onChange={(e) => setRating(e.target.value)}
                                      >
                                        <option defaultValue>Select On Option</option>
                                        <option value="bad">Bad</option>
                                        <option value="poor">Poor</option>
                                        <option value="average">Average</option>
                                        <option value="good">Good</option>
                                        <option value="excellent">Excellent</option>
                                      </select>
                                    </div>
                                  </div>

                                  <div className="col-md-12 mb-3 px-2">
                                    <div className="input-cust-div">
                                      <label htmlFor="feedback" className="form-label">
                                        <b>Feedback</b> (optional)
                                      </label>
                                      <textarea
                                        type="text"
                                        className="form-control"
                                        id="feedback"
                                        name="feedback"
                                        value={feedback}
                                        placeholder="enter your feedback"
                                        onChange={(e) => setFeedback(e.target.value)}
                                      />
                                    </div>
                                  </div>

                                  <div className="">
                                    <button className="btn btn-primary text-white float-right">
                                      Send Feedback
                                    </button>
                                  </div>

                                </div>
                              </div>
                            </form>
                          </div>
                        </>

                        :

                        <>
                          <hr />
                          <div className="card-body pt-0">

                            {
                              validationErrors ?
                                <div className="alert alert-danger alert-dismissible" role="alert">
                                  <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                  <ul className="mb-0">
                                    {errors.map((error) => {
                                      return (
                                        <li key={error.id} className='list'>
                                          <span className='repo-text'>{error} </span>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                                :
                                <></>
                            }
                            {
                              message ?
                                <div className="alert alert-success alert-dismissible" role="alert">
                                  <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                  <h6 className="mb-0">{message}</h6>
                                </div>
                                :
                                <></>
                            }

                            <h4 className="text-info">Offers Received On This Service</h4>
                            {/* <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th style={{ width: "10%" }}>IMAGE</th>
                                    <th style={{ width: "30%" }}>TUTOR</th>
                                    <th>OFFER (PKR)</th>
                                    <th>ACTION</th>
                                  </tr>
                                </thead>
                                <tbody>

                                  {service.applicants.map((applicant) => {
                                    return (
                                      <tr key={applicant.id}>
                                        <td>
                                          <img width={50} height={50} src={require('../../../src/assets/placeholders/profie.jpg')} alt="main logo" />
                                        </td>
                                        <td>
                                          <h5 className="mb-0">{applicant.user.name}</h5>
                                          <p className="text-muted m-0"><small>{applicant.user.email}</small></p>
                                          <p className="text-info m-0"><small>{applicant.user.tutor.degree}</small></p>
                                        </td>
                                        <td>
                                          <p className="badge badge-primary px-4 py-2 mb-0">
                                            {applicant.offer} PKR
                                          </p>
                                          <p><small className="text-muted">{applicant.comment}</small></p>
                                        </td>

                                        <td>
                                          {(() => {
                                            if (applicant.is_accepted == null) {
                                              return (
                                                <>
                                                  <button
                                                    className="btn btn-sm btn-info text-white"
                                                    type="button"
                                                  // onClick={() => acceptOffer(applicant.id)}
                                                  >
                                                    <b>Accept</b>
                                                  </button>
                                                </>
                                              )
                                            } else if (applicant.is_accepted == 1) {
                                              return (
                                                <>

                                                  <strong className="offer-status text-white mb-1">Accepted</strong>
                                                  <br />
                                                  {
                                                    service.status == "active" ?
                                                      <>
                                                        <Link to={'/student/service/' + service.id + '/feedback/' + applicant.id} >
                                                          <strong className="offer-status text-white">Feedback</strong>
                                                        </Link>
                                                      </>
                                                      :
                                                      <>
                                                      </>
                                                  }

                                                </>
                                              )
                                            }
                                          })()}

                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div> */}
                          </div>
                        </>
                      }
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div >
          :

          <img className="d-flex mx-auto" src={require('../../../src/assets/preloaders/loader2.gif')} width="50%" height="auto" />
      }
    </>
  );
}

export default Feedback;
