import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./service.css";
import { api_url } from "../../config";
import Sidebar from "../sidebar/Sidebar";


// CREATE SERVICE COMPONENT
function Service() {

  // USE-STATE HOOKS FOR MANAGING SERVICE MODEL
  const [action, setAction] = useState("create");
  const [serverError, setServerError] = useState(false);
  const [preloader, setPreloader] = useState(false);
  const [validationErrors, setValidationErros] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);


  // SERVICE IPUTS
  const [subjects, setSubjects] = useState([]);
  const [title, setTitle] = useState(" ");
  const [description, setDescription] = useState(" ");
  const [subject, setSubject] = useState(1);
  const [platform, setPlatform] = useState(" ");
  const [budget, setBudget] = useState(" ");
  const [startDate, setStartDate] = useState(" ");
  const [status, setStatus] = useState(" ");
  const [isActive, setIsActive] = useState(false);


  // useNavigate hook for redirect after login
  const navigate = useNavigate();


  // useParam hook to get params from url
  const params = useParams();
  const serviceId = params.id;
  // console.log(serviceId);


  const token = localStorage.getItem("token");
  let user = JSON.parse(localStorage.getItem("user"));
  // console.log(user);


  useEffect(() => {
    getAllSubjects();

    if (serviceId) {
      setAction('edit');
      getServiceDetail();
    } else {
      setAction('create');
    }
  }, [])


  /*
  |========================================================
  | Get All Subjects -- API CALLING
  |========================================================
  */
  async function getAllSubjects(e) {

    let url = api_url + "subjects";

    const response = await axios.get(
      url,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );

    // console.log(response);
    if (response && response.status == 200) {

      let subjects = response.data.subjects;
      setSubjects(subjects);
      // console.log(subjects);
    }
    else {
      // console.log("API CALLING FAILED");
    }

  }


  /*
  |========================================================
  | Get Service Detail -- API CALLING
  |========================================================
  */
  async function getServiceDetail(e) {

    let url = api_url + "user/service/" + serviceId + "/edit";
    // console.log(url);

    const response = await axios.get(
      url,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }
    );

    console.log(response);
    if (response && response.status == 200) {

      setPreloader(false);

      let service = response.data.service;
      setTitle(service.title);
      setDescription(service.description);
      setSubject(service.subject_id);
      setPlatform(service.want_to_learn);
      setBudget(service.budget);
      setStartDate(service.start_date);
      setStatus(service.status);
      setIsActive(service.is_active);
      // console.log(service);
    }
    else {
      console.log("API FAILED");
    }

  }



  /*
  |========================================================
  | STORE NEW SERVICE FOR STUDENT -- API CALLING
  |========================================================
  */
  async function storeService(e) {
    e.preventDefault();

    setPreloader(true);

    // get token from local storage
    const token = localStorage.getItem("token");
    // console.log(token);

    // MAKE FORMDATA FOR CALLING STORE NEW SERVER API
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("subject_id", subject);
    formData.append("want_to_learn", platform);
    formData.append("budget", budget);
    formData.append("start_date", startDate);
    formData.append("status", status);
    const body = formData;

    let url = "";
    //  Create/Update END-POINT
    if (action == "create") {
      url = api_url + "user/service/store";
    } else {
      url = api_url + "user/service/" + serviceId + "/update";
    }

    const response = await axios.post(
      url,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }
    );

    // console.log(response);
    if (response && response.status == 200) {
      setPreloader(false);

      let data = response.data;
      if (data.status == 200) {

        let message = data.message;
        setMessage(message);
        setSuccessMessage(true);

        // navigate("/student/services");

      } else {
        setErrors(data.errors);
        setValidationErros(true);
      }

    } else {
      console.log("API CALLING FAILED");
    }


  }
  return (
    <>
      {
        !preloader ?

          <div className="container py-5">
            <div className="row">

              <div className="col-md-3">
                <Sidebar title="add-service" />
              </div>

              <div className="col-md-9 px-0">

                <h3 className="card-heading">{action == "create" ? "Add New Service" : "Edit This Service"}</h3>

                <div className="card">
                  <div className="card-body p-4">

                    {
                      validationErrors ?
                        <div className="alert alert-danger alert-dismissible" role="alert">
                          <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                          <ul className="mb-0">
                            {errors.map((error) => {
                              return (
                                <li key={error.id} className='list'>
                                  <span className='repo-text'>{error} </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        :
                        <></>
                    }
                    {
                      message ?
                        <div className="alert alert-success alert-dismissible" role="alert">
                          <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                          <h6 className="mb-0">{message}</h6>
                        </div>
                        :
                        <></>
                    }

                    <form onSubmit={storeService}>
                      <div className="row">

                        <h6>Please Fill out service details</h6>

                        <div className="col-md-12 mb-3 px-2">
                          <div className="input-cust-div">
                            <label htmlFor="title" className="form-label">
                              <b>Service Title</b> <small>(required)</small>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="title"
                              name="title"
                              value={title}
                              required
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-md-12 mb-3 px-2">
                          <div className="input-cust-div">
                            <label htmlFor="description" className="form-label">
                              <b>Service Detail</b> <small>(optional)</small>
                            </label>
                            <textarea
                              type="text"
                              className="form-control"
                              id="description"
                              name="description"
                              placeholder="enter service details"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-md-4 mb-3 px-2">
                          <div className="input-cust-div">
                            <label htmlFor="subject_id" className="form-label">
                              <b>Subject</b> <small>(required)</small>
                            </label>
                            <select
                              id="subject_id"
                              name="subject_id"
                              className="form-select"
                              value={subject}
                              required
                              onChange={(e) => setSubject(e.target.value)}
                            >
                              {subjects.map(subject => (
                                <option key={subject.id} value={subject.id}>
                                  {subject.title}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-4 mb-3 px-2">
                          <div className="input-cust-div">
                            <label htmlFor="want_to_learn" className="form-label">
                              <b>Want To Learn</b> <small>(required)</small>
                            </label>
                            <select
                              id="want_to_learn"
                              name="want_to_learn"
                              className="form-select"
                              value={platform}
                              required
                              onChange={(e) => setPlatform(e.target.value)}
                            >
                              <option>select platform</option>
                              <option value="online">Online</option>
                              <option value="offline" >Offline</option>
                              {/* <option value="both">Both</option> */}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-4 mb-3 px-2">
                          <div className="input-cust-div">
                            <label htmlFor="budget" className="form-label">
                              <b>Budget in PKR</b> <small>(required)</small>
                            </label>
                            <input
                              type="number"
                              min={0}
                              className="form-control"
                              id="budget"
                              name="budget"
                              value={budget}
                              required
                              onChange={(e) => setBudget(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-md-6 mb-3 px-2">
                          <div className="input-cust-div">
                            <label htmlFor="start_date" className="form-label">
                              <b>Start From</b> <small>(required)</small>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              id="start_date"
                              name="start_date"
                              value={startDate}
                              required
                              onChange={(e) => setStartDate(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-md-6 mb-3 px-2">
                          <div className="input-cust-div">
                            <label htmlFor="status" className="form-label">
                              <b>Service Status</b> <small>(required)</small>
                            </label>
                            <select
                              id="status"
                              name="status"
                              className="form-select"
                              value={status}
                              required
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              <option value="active">Active</option>
                              <option value="inactive" >Inactive</option>
                              <option value="cancelled" >Concelled</option>
                              <option value="completed" >Completed</option>
                            </select>
                          </div>
                        </div>

                        <div className="mt-4">
                          <button className="btn btn-primary text-white float-right">
                            {action == "create" ? "Create Service" : "Update Service"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

              </div>
            </div>
          </div>

          :

          <img className="d-flex mx-auto" src={require('../../../src/assets/preloaders/loader2.gif')} width="50%" height="auto" />
      }
    </>
  );
}

export default Service;
