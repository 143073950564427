import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

import "./services.css";
import Sidebar from "../sidebar/Sidebar";
import { Link } from "react-router-dom";
import { api_url, img_url } from "../../config";


function ServiceList() {

  // USE USE-STATE HOOKS TO MANAGE PAGE CONTENT
  const [actor, setActor] = useState(" ");
  let user = JSON.parse(localStorage.getItem("user"));

  const [preloader, setPreloader] = useState(true);
  const [services, setServices] = useState([]);

  const location = useLocation();
  const path = location.pathname;
  // console.log(path);

  const token = localStorage.getItem("token");

  useEffect(() => {
    getStudentServices();

    const role = localStorage.getItem("role");
    setActor(role);

  }, [])

  // get student services
  async function getStudentServices(e) {
    let url = api_url + "user/services";

    const response = await axios.get(
      url,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }
    );

    // console.log(response);
    if (response && response.status == 200) {

      setPreloader(false);

      let services = response.data.services;
      setServices(services);
      // console.log(services);
    }
    else {

    }

  }

  return (
    <>
      {
        !preloader ?

          <div className="container py-5">
            <div className="row">

              <div className="col-md-3">
                <Sidebar title="services" />
              </div>

              <div className="col-md-9">
                <div className="row g-0">
                  <h3 className="card-heading">Your Services</h3>

                  {services.map((service) => {
                    return (

                      <div className="col-md-12 px-0" key={service.id}>
                        <div className="card mb-3">

                          <div className="card-header pb-2 border-0 offer-card-bg">
                            <h6 className="text-muted">SERVICE</h6>
                            <h3 className="card-title mb-0 text-primary-cust"><strong>{service.title}</strong></h3>
                            <h4 className="card-title">Budget: {service.budget} PKR</h4>
                          </div>

                          <div className="card-body">
                            <p>{service.description}</p>
                            <div className="row">
                              <div className="col-3 mt-3">
                                <p className="mb-0">Subject</p>
                                <b> {service.subject.title}</b>
                              </div>
                              <div className="col-3 mt-3">
                                <p className="mb-0">Want To Read</p>
                                <b> {service.want_to_learn}</b>
                              </div>
                              <div className="col-3 mt-3">
                                <p className="mb-0">Status</p>
                                <b> {service.status}</b>
                              </div>
                              <div className="col-3">
                                {
                                  actor == "student" ?
                                    <>
                                      <Link to={'/student/service/' + service.id + '/edit'} style={{ textDecoration: "none" }}>
                                        <button className="btn btn-dark w-75 float-right mb-1">EDIT DETAIL</button>
                                      </Link>
                                      <Link to={'/student/service/' + service.id} style={{ textDecoration: "none" }}>
                                        <button className="btn btn-dark w-75 float-right">VIEW DETAIL</button>
                                      </Link>
                                    </>
                                    :
                                    <>
                                      <Link to={'/tutor/service/' + service.id} style={{ textDecoration: "none" }}>
                                        <button className="btn btn-dark w-75 float-right">VIEW DETAIL</button>
                                      </Link>
                                    </>
                                }
                              </div>
                            </div>
                            {/* <p className="card-text">
                          <small className="text-body-secondary">
                            <i className="fa fa-user p-2"></i>
                            <b>
                              {service.student.name}
                            </b>
                          </small>
                          <i className="fa fa-globe p-2 ml-5"></i>
                          <small className="text-body-secondary">{service.student.address}</small>
                        </p> */}
                          </div>

                          <div className="card-footer border-0 offer-card-bg">
                            <h5 className="card-title"><strong>Feedback</strong></h5>
                            {service.feedback ?
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="row">
                                    <div className="col-md-2 px-0">
                                      {service.feedback.student.profile_image ?
                                        <img className="d-flex mx-auto rounded-circle mw-100" width={60} height={60} src={img_url + "storage/images/users/sm/" + service.feedback.student.profile_image} alt="main logo" />
                                        :
                                        <img width={60} height={60} className="mw-100" src={require('../../../src/assets/placeholders/profie.jpg')} alt="main logo" />
                                      }
                                    </div>
                                    <div className="col-md-10">
                                      <h6 className="text-left mb-0">{service.feedback.student.name}</h6>
                                      <p className="text-left mb-0">{service.feedback.student.email}</p>
                                      <hr />
                                      <h6 className="text-capitalize">{service.feedback.student_ratings}</h6>
                                      <p>{service.feedback.student_feedback}</p>

                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row">
                                    <div className="col-md-2 px-0">
                                      {service.feedback.tutor.profile_image ?
                                        <img className="d-flex mx-auto rounded-circle mw-100" width={60} height={60} src={img_url + "storage/images/users/sm/" + service.feedback.tutor.profile_image} alt="main logo" />
                                        :
                                        <img width={60} height={60} className="mw-100" src={require('../../../src/assets/placeholders/profie.jpg')} alt="main logo" />
                                      }
                                    </div>
                                    <div className="col-md-10">
                                      <h6 className="text-left mb-0">{service.feedback.tutor.name}</h6>
                                      <p className="text-left mb-0">{service.feedback.tutor.email}</p>
                                      <hr />
                                      <h6 className="text-capitalize">{service.feedback.tutor_ratings}</h6>
                                      <p>{service.feedback.tutor_feedback}</p>

                                    </div>
                                  </div>
                                </div>
                              </div>
                              :
                              <h6>No Feedback Yet</h6>
                            }
                          </div>

                        </div>
                      </div>
                    );
                  })}

                </div>
              </div>
            </div>
          </div >

          :

          <img className="d-flex mx-auto" src={require('../../../src/assets/preloaders/loader2.gif')} width="50%" height="auto" />
      }
    </>
  );
}

export default ServiceList;
