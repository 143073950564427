import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import "./services.css";
import Sidebar from "../sidebar/Sidebar";
import { Link } from "react-router-dom";
import { api_url } from "../../config";
import { param } from "jquery";
import { img_url } from "../../config";




/*
|========================================================
| SERVICE DETAIL - MIAN COMPONENT
|========================================================
*/
function ServiceDetail() {

  // USE USE-STATE HOOKS TO MANAGE PAGE CONTENT
  const [service, setService] = useState(" ");
  const [offer, setOffer] = useState(" ");
  const [comment, setComment] = useState(" ");
  const [preloader, setPreloader] = useState(true);
  const [applicantId, setApplicantId] = useState("");
  const [validationErrors, setValidationErros] = useState(false);
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState(false);
  const [message, setMessage] = useState("");

  // useNavigate hook for redirect after login
  const navigate = useNavigate();

  // useLocation hook to get url complete detail
  const location = useLocation();
  const path = location.pathname;
  // console.log(path);

  // useParam hook to get params from url
  const params = useParams();
  const serviceId = params.id;
  // console.log(serviceId);

  const token = localStorage.getItem("token");
  let user = JSON.parse(localStorage.getItem("user"));
  // console.log(user);

  useEffect(() => {
    getStudentServiceDetail();
  }, [])



  /*
  |========================================================
  | GET STUDENT SERVICE DETAIL API CALLING
  |========================================================
  */
  async function getStudentServiceDetail(e) {

    let url = api_url + "user/service/" + serviceId + "/edit";
    // console.log(url);

    const response = await axios.get(
      url,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }
    );

    // console.log(response);
    if (response && response.status == 200) {

      setPreloader(false);

      let service = response.data.service;
      setService(service);
      // console.log(service);
    }
    else {
      console.log("API FAILED");
    }

  }


  /*
  |========================================================
  | SEND OFFER ON THIS SERVICE -- API CALLING
  |========================================================
  */
  async function sendOffer(e) {
    e.preventDefault();

    const token = localStorage.getItem("token");
    // console.log(token);

    // make formData for calling send offer api
    const formData = new FormData();
    formData.append("offer", offer);
    formData.append("comment", comment);
    const body = formData;

    //  API post method
    let url = api_url + "user/service/" + serviceId + "/offer";
    // console.log(url);

    const response = await axios.post(
      url,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }
    );

    if (response && response.status == 200) {
      setPreloader(false);

      const data = response.data;
      if (data.status == 200) {
        let message = data.message;
        setMessage(message);
        setSuccessMessage(true);

      } else {
        setErrors(data.errors);
        setValidationErros(true);
      }

    } else {
      console.log("API CALLING FAILED");
    }

  }


  /*
  |========================================================
  | ACCEPT/REJECT OFFER API CALLING
  |========================================================
  */
  async function acceptOffer(applicantId) {

    const token = localStorage.getItem("token");
    setApplicantId(applicantId);
    // console.log(applicant_id);


    // make formData for update tutor profile api
    const formData = new FormData();
    formData.append("applicant_id", applicantId);
    const body = formData;

    //  API post method
    let url = api_url + "user/service/" + serviceId + "/accept-offer";
    // console.log(url);

    const response = await axios.post(
      url,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }
    );

    // console.log(response.data);
    if (response && response.status == 200) {
      // setPreloader(false);

      const data = response.data;
      if (data.status == 200) {

        let message = data.message;
        let service = data.service;
        setMessage(message);
        setSuccessMessage(true);

        console.log("here");

        window.location.reload(true)


      } else {
        setErrors(data.errors);
        setValidationErros(true);
      }

    } else {
      console.log("API CALLING FAILED");
    }

  }

  return (
    <>
      {
        !preloader ?
          <div className="container py-5">
            <div className="row">

              <div className="col-md-3">
                <Sidebar title="service-detail" />
              </div>

              <div className="col-md-9">
                <div className="row g-0">

                  <h3 className="card-heading">View Service Detail
                    <Link to="/student/services" style={{ float: "right" }}>
                      <button className="btn btn-dark">BACK</button>
                    </Link>
                  </h3>

                  <div className="col-md-12 px-0">
                    <div className="card mb-3">

                      <div className="card-header pb-2 border-0 offer-card-bg">
                        <h6 className="text-muted">SERVICE</h6>
                        <h3 className="card-title mb-0 text-primary-cust"><strong>{service.title}</strong></h3>
                        <h4 className="card-title">Budget: {service.budget} PKR</h4>
                      </div>

                      <div className="card-body">
                        <p>{service.description}</p>
                        <div className="row">
                          <div className="col-3">
                            <p className="mb-0">Subject</p>
                            <b> {service.subject.title}</b>
                          </div>
                          <div className="col-3">
                            <p className="mb-0">Want To Read</p>
                            <b> {service.want_to_learn}</b>
                          </div>
                          <div className="col-3">
                            <p className="mb-0">Status</p>
                            <b> {service.status}</b>
                          </div>
                          <div className="col-3">
                            <p className="mb-0">Total Applicants</p>
                            <b> {service.applicants_count}</b>
                          </div>
                        </div>
                      </div>

                      {user.role_id == 2 ?
                        <>
                          <hr />
                          <div className="card-body col-md-8 mx-auto pt-3">
                            <h4 className="text-info">Send An Offer</h4>
                            <form onSubmit={sendOffer}>
                              <div className="row">

                                {
                                  validationErrors ?
                                    <div className="alert alert-danger alert-dismissible" role="alert">
                                      <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                      <ul className="mb-0">
                                        {errors.map((error) => {
                                          return (
                                            <li key={error.id} className='list'>
                                              <span className='repo-text'>{error} </span>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                    :
                                    <></>
                                }

                                {
                                  message ?
                                    <div className="alert alert-success alert-dismissible" role="alert">
                                      <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                      <h6 className="mb-0">{message}</h6>
                                    </div>
                                    :
                                    <></>
                                }

                                <div className="col-md-12 mb-3 px-2">
                                  <div className="input-cust-div">
                                    <label htmlFor="offer" className="form-label">
                                      <b>Offer Amount in PKR</b> (required)
                                    </label>
                                    <input
                                      type="number"
                                      min={0}
                                      required
                                      className="form-control"
                                      id="offer"
                                      name="offer"
                                      onChange={(e) => setOffer(e.target.value)}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-12 mb-3 px-2">
                                  <div className="input-cust-div">
                                    <i className="fa fa-home p-2" aria-hidden="true"></i>
                                    <label htmlFor="complete_address" className="form-label">
                                      <b>Complete Address</b> (optional)
                                    </label>
                                    <textarea
                                      type="text"
                                      className="form-control"
                                      id="comment"
                                      name="comment"
                                      placeholder="enter your comment"
                                      onChange={(e) => setComment(e.target.value)}
                                    />
                                  </div>
                                </div>

                                <div className="">
                                  <button className="btn btn-primary text-white float-right">
                                    Send Offer
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </>

                        :

                        <>
                          <hr />
                          <div className="card-body pt-0">

                            {
                              validationErrors ?
                                <div className="alert alert-danger alert-dismissible" role="alert">
                                  <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                  <ul className="mb-0">
                                    {errors.map((error) => {
                                      return (
                                        <li key={error.id} className='list'>
                                          <span className='repo-text'>{error} </span>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                                :
                                <></>
                            }
                            {
                              message ?
                                <div className="alert alert-success alert-dismissible" role="alert">
                                  <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                  <h6 className="mb-0">{message}</h6>
                                </div>
                                :
                                <></>
                            }

                            <h4 className="text-info">Offers Received On This Service</h4>
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th style={{ width: "10%" }}>IMAGE</th>
                                    <th style={{ width: "30%" }}>TUTOR</th>
                                    <th>OFFER (PKR)</th>
                                    <th>ACTION</th>
                                  </tr>
                                </thead>
                                <tbody>

                                  {service.applicants.map((applicant) => {
                                    return (
                                      <tr key={applicant.id}>

                                        <td>
                                          {applicant.user.profile_image ?
                                            <img className="rounded-circle" width={50} height={50} src={img_url + "storage/images/users/sm/" + applicant.user.profile_image} alt="main logo" />
                                            :
                                            <img width={50} height={50} src={require('../../../src/assets/placeholders/profie.jpg')} alt="main logo" />
                                          }
                                        </td>

                                        <td>
                                          <h5 className="mb-0">{applicant.user.name}</h5>
                                          <p className="text-muted m-0"><small>{applicant.user.email}</small></p>
                                          <p className="text-info m-0"><small>{applicant.user.tutor.degree}</small></p>
                                        </td>
                                        <td>
                                          <p className="badge badge-primary px-4 py-2 mb-0">
                                            {applicant.offer} PKR
                                          </p>
                                          <p><small className="text-muted">{applicant.comment}</small></p>
                                        </td>

                                        <td>
                                          {(() => {
                                            if (applicant.is_accepted == null) {
                                              return (
                                                <>
                                                  <button
                                                    className="btn btn-sm btn-info text-white"
                                                    type="button"
                                                    onClick={() => acceptOffer(applicant.id)}
                                                  >
                                                    <b>Accept</b>
                                                  </button>
                                                </>
                                              )
                                            } else if (applicant.is_accepted == 1) {
                                              return (
                                                <>

                                                  <strong className="offer-status text-white mb-1">Accepted</strong>
                                                  <br />
                                                  {
                                                    service.status == "completed" ?
                                                      <>
                                                        <Link to={'/student/service/' + service.id + '/feedback/' + applicant.id} >
                                                          <strong className="offer-status text-white">Feedback</strong>
                                                        </Link>
                                                      </>
                                                      :
                                                      <>
                                                      </>
                                                  }

                                                </>
                                              )
                                            }
                                          })()}

                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      }
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div >
          :

          <img className="d-flex mx-auto" src={require('../../../src/assets/preloaders/loader2.gif')} width="50%" height="auto" />
      }
    </>
  );
}

export default ServiceDetail;
