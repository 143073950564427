import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./profile.css";
// import { api_url } from "../../config";
import { api_url, img_url } from "../../config";
import Sidebar from "../sidebar/Sidebar";


// PROFILE COMPONENT
function Profile() {

  // useState hooks for handling student profile form inputs data
  const [actor, setActor] = useState("");
  const [serverError, setServerError] = useState(false);
  const [preloader, setPreloader] = useState(true);
  const [validationErrors, setValidationErros] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);

  // COMMON INPUTS
  const [name, setName] = useState("");
  const [email, setEmail] = useState(" ");
  const [countryCode, setCountryCode] = useState(" ");
  const [mobileNo, setMobileNo] = useState(" ");
  const [countryId, setCountryId] = useState(" ");
  const [cityId, setCityId] = useState(" ");
  const [completeAddress, setCompleteAddress] = useState(" ");
  const [nic, setNic] = useState(" ");
  const [gender, setGender] = useState(" ");
  const [dateOfBirth, setDateOfBirth] = useState(" ");
  const [bio, setBio] = useState(" ");
  const [userImage, setUserImage] = useState(" ");
  const [profileImage, setProfileImage] = useState([]);

  // STUDENT ONLY INPUTS
  const [standard, setStandard] = useState(" ");

  // TUTOR ONLY INPUTS 
  const [degree, setDegree] = useState(" ");
  const [experience, setExperience] = useState(" ");
  const [teaches, setTeaches] = useState(" ");
  const [availability, setAvailability] = useState(" ");
  const [subject, setSubject] = useState(" ");
  const [subjects, setSubjects] = useState([]);


  // useNavigate hook for redirect after login
  const navigate = useNavigate();


  // function to convert image file to base64
  const convertBase64 = (file) => new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)

    fileReader.onload = () => {
      resolve(fileReader.result)
    }

    fileReader.onerror = (error) => {
      reject(error)
    }
  })

  const imageUpload = async event => {
    const file = event.target.files[0]
    const base64 = await convertBase64(file)

    setProfileImage(base64);
  }

  // get token from local storage
  const token = localStorage.getItem("token");


  // const options = [
  //   { value: 1, label: 'Chocolate' },
  //   { value: 2, label: 'Strawberry' },
  //   { value: 3, label: 'Vanilla' }
  // ]


  // const handleChange = (selectedOption) => {

  //   const subjects = selectedOption;
  //   const tutorSubsjects = subjects.map(subject => subject.value)
  //   setSubjects(tutorSubsjects);
  //   console.log(`Option selected:`,  tutorSubsjects);
  // };



  /*
  |========================================================
  | USE-EFFECT HOOK FOR CALLING API
  |========================================================
  */
  useEffect(() => {
    getProfileInfo();
    getAllSubjects();
  }, [])



  /* 
  |========================================================
  | Get User Profile API Calling
  |========================================================
  */
  async function getProfileInfo(e) {
    let url = api_url + "user/profile/edit";

    const response = await axios.get(
      url,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }
    );

    // console.log(response);

    if (response && response.status == 200) {

      setPreloader(false);

      // let data = response.data;
      let user = response.data.user;

      // update user object in local storage
      localStorage.setItem('user', JSON.stringify(user));

      // set profile input values
      setName(user.name);
      setEmail(user.email);
      setCountryCode(user.country_code);
      setMobileNo(user.mobile_no);
      setNic(user.nic);
      setCompleteAddress(user.complete_address);
      setDateOfBirth(user.date_of_birth);
      setCountryId(user.country_id);
      setCityId(user.city_id);
      setGender(user.gender);
      setUserImage(user.profile_image);

      // set login user role
      if (user.role_id == 2) {
        setActor("Tutor");
        setBio(user.tutor.bio);
        setDegree(user.tutor.degree);
        setExperience(user.tutor.experience);
        setTeaches(user.tutor.teaches);
        setAvailability(user.tutor.currently_available);
      }
      else {
        setActor("Student");
        setBio(user.student.bio);
        setStandard(user.student.class);
      }

    } else {

    }

  }


  /*
  |========================================================
  | Get All Subjects -- API CALLING
  |========================================================
  */
  async function getAllSubjects(e) {

    let url = api_url + "subjects";

    const response = await axios.get(
      url,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );

    // console.log(response);
    if (response && response.status == 200) {

      let subjects = response.data.subjects;
      setSubjects(subjects);
      // console.log(subjects);
    }
    else {
      // console.log("API CALLING FAILED");
    }

  }



  /*
  |========================================================
  | Update Profile Form Event Handler -- UPDATE PROFILE
  |========================================================
  */
  async function updateProfileData(e) {
    e.preventDefault();

    setPreloader(true);

    const token = localStorage.getItem("token");
    // console.log(token);

    // make formData for update tutor profile api
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("country_code", countryCode);
    formData.append("mobile_no", mobileNo);
    formData.append("country_id", countryId);
    formData.append("city_id", cityId);
    formData.append("complete_address", completeAddress);
    formData.append("nic", nic);
    formData.append("gender", gender);
    formData.append("date_of_birth", dateOfBirth);
    formData.append("bio", bio);
    formData.append("profile_image", profileImage);

    let user = JSON.parse(localStorage.getItem("user"));
    // console.log(user);

    // role specific inputs
    if (user.role_id == 3) {
      formData.append("class", standard);

    } else {
      formData.append("degree", degree);
      formData.append("experience", experience);
      formData.append("teaches", teaches);
      formData.append("currently_available", availability);
      formData.append("subject", subject);
      formData.append("subjects", subjects);
    }
    const body = formData;

    //  API post method
    // console.log(api_url);
    let url = api_url + "user/profile/update";

    const response = await axios.post(
      url,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }
    );

    // console.log(response);
    if (response && response.status == 200) {
      setPreloader(false);

      let profImage = response.data.user.profile_image;
      // console.log(profImage);
      setUserImage(profImage);

      // window.location.reload();


      let data = response.data;
      if (data.status == 200) {

        let message = data.message;
        setMessage(message);
        setSuccessMessage(true);

      } else {
        setErrors(data.errors);
        setValidationErros(true);
      }

    } else {
      console.log("API CALLING FAILED");
    }



  }
  return (
    <>
      {
        !preloader ?

          <div className="container py-5">
            <div className="row">

              <div className="col-md-3">
                <Sidebar title="profile" pic={userImage} />
              </div>

              <div className="col-md-9 px-0">
                <h3 className="card-heading">{actor} Profile Information</h3>


                {
                  validationErrors ?
                    <div className="alert alert-danger alert-dismissible" role="alert">
                      <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                      <ul className="mb-0">
                        {errors.map((error) => {
                          return (
                            <li key={error.id} className='list'>
                              <span className='repo-text'>{error} </span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    :
                    <></>
                }
                {
                  message ?
                    <div className="alert alert-success alert-dismissible" role="alert">
                      <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                      <h6 className="mb-0">{message}</h6>
                    </div>
                    :
                    <></>
                }

                <div className="card">
                  {/* <div className="card-header border-0" style={{ backgroundColor: "#0cd2ff" }} >
                    <h2 className="">{actor} Profile Information</h2>
                  </div> */}

                  <div className="card-body p-4">
                    <form onSubmit={updateProfileData}>
                      <div className="row">

                        <h6>BASIC INFORMATION</h6>
                        <div className="col-md-6 mb-3 px-2">
                          <div className="input-cust-div">
                            <i className="fa fa-user p-2" aria-hidden="true"></i>
                            <label htmlFor="inputEmail4" className="form-label">
                              Full Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              value={name}
                              required
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </div>
                        {/* 
                        <div className="col-md-6 mb-3 px-2">
                          <div className="input-cust-div">
                            <Select isMulti options={options} onChange={handleChange} autoFocus={true} />
                          </div>
                        </div> */}


                        <div className="col-md-6 mb-3 px-2">
                          <div className="input-cust-div">
                            <i className="fa fa-envelope p-2" aria-hidden="true"></i>
                            <label htmlFor="inputEmail4" className="form-label">
                              Email
                            </label>
                            <input
                              readOnly
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-md-2 mb-3 px-2">
                          <div className="input-cust-div">
                            <i className="fas fa-globe p-2"></i>
                            <label htmlFor="country_code" className="form-label">
                              Dial Code
                            </label>
                            <select
                              id="country_code"
                              name="country_code"
                              className="form-select"
                              value={countryCode}
                              required
                              onChange={(e) => setCountryCode(e.target.value)}
                            >
                              <option value="+92" >+92</option>
                              {/* <option value="+91" >+91</option>
                              <option value="+974">+974</option> */}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-4 mb-3 px-2">
                          <div className="input-cust-div">
                            <i className="fas fa-phone p-2"></i>
                            <label htmlFor="inputPassword4" className="form-label">
                              Mobile Number
                            </label>
                            <input
                              type="number"
                              min={0}
                              required
                              className="form-control"
                              id="mobile_no"
                              name="mobile_no"
                              value={mobileNo}
                              onChange={(e) => setMobileNo(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-md-6 mb-3 px-2">
                          <div className="input-cust-div">
                            <i
                              className="fa fa-id-card p-2"
                              aria-hidden="true"
                            ></i>
                            <label htmlFor="nic" className="form-label">
                              NIC
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="nic"
                              name="nic"
                              value={nic}
                              onChange={(e) => setNic(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-md-6 mb-3 px-2">
                          <div className="input-cust-div">
                            <i className="fas fa-globe p-2"></i>
                            <label htmlFor="country_id" className="form-label">
                              Country
                            </label>
                            <select
                              id="country_id"
                              name="country_id"
                              className="form-select"
                              value={countryId}
                              onChange={(e) => setCountryId(e.target.value)}
                            >
                              <option defaultValue>Choose...</option>
                              <option value={1}>Pakistan</option>
                              {/* <option value={2}>India</option>
                              <option value={3}>Bangladesh</option> */}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 mb-3 px-2">
                          <div className="input-cust-div">
                            <i className="fas fa-globe p-2"></i>
                            <label htmlFor="city_id" className="form-label">
                              City
                            </label>
                            <select
                              id="city_id"
                              name="city_id"
                              value={cityId}
                              className="form-select"
                              onChange={(e) => setCityId(e.target.value)}
                            >
                              <option defaultValue>Choose...</option>
                              <option value={1}>Karachi</option>
                              <option value={2}>Lahore</option>
                              <option value={3}>Islamabad</option>
                              <option value={4}>Rawalpindi</option>
                              <option value={5}>Multan</option>
                              <option value={6}>Peshawar</option>
                              <option value={7}>Queta</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12 mb-3 px-2">
                          <div className="input-cust-div">
                            <i className="fa fa-home p-2" aria-hidden="true"></i>
                            <label htmlFor="complete_address" className="form-label">
                              Complete Address
                            </label>
                            <textarea
                              type="text"
                              className="form-control"
                              id="complete_address"
                              name="complete_address"
                              placeholder="enter your address"
                              value={completeAddress}
                              onChange={(e) => setCompleteAddress(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-md-6 mb-3 px-2">
                          <div className="input-cust-div">
                            <i className="fas fa-male p-2"></i>
                            <label htmlFor="gender" className="form-label">
                              Gender
                            </label>
                            <select
                              id="gender"
                              name="gender"
                              className="form-select"
                              value={gender}
                              onChange={(e) => setGender(e.target.value)}
                            >
                              <option defaultValue>Choose...</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                              <option value="other">Other</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-6 mb-3 px-2">
                          <div className="input-cust-div">
                            <i
                              className="fa fa-birthday-cake p-2"
                              aria-hidden="true"
                            ></i>
                            <label htmlFor="date_of_birth" className="form-label">
                              Date Of Birth
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              id="date_of_birth"
                              name="date_of_birth"
                              value={dateOfBirth}
                              onChange={(e) => setDateOfBirth(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-md-12 mb-3 px-2">
                          <div className="input-cust-div">
                            <i className="fa fa-upload p-2" aria-hidden="true"></i>

                            <label htmlFor="inputBirthday" className="form-label">
                              Upload Profile Pic
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              id="profile_image"
                              name="profile_image"
                              onChange={imageUpload}
                            />
                          </div>
                        </div>

                        <hr />
                        <h6>ADVANCE INFORMATION</h6>
                        <div className="col-md-12 mb-3 px-2">
                          <div className="input-cust-div">
                            <label htmlFor="bio" className="form-label">
                              <strong>Bio</strong>
                            </label>
                            <textarea
                              type="text"
                              className="form-control"
                              id="bio"
                              name="bio"
                              value={bio}
                              placeholder="introduce yourself"
                              onChange={(e) => setBio(e.target.value)}
                            />
                          </div>
                        </div>
                        {
                          actor == "Student" ? (
                            <div className="col-md-6 mb-3 px-2">
                              <div className="input-cust-div">
                                <i
                                  className="fa fa-id-card p-2"
                                  aria-hidden="true"
                                ></i>
                                <label htmlFor="class" className="form-label">
                                  <strong>Class / Standard</strong>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="class"
                                  name="class"
                                  value={standard}
                                  onChange={(e) => setStandard(e.target.value)}
                                />
                              </div>
                            </div>
                          )
                            :
                            (
                              <>
                                <div className="col-md-4 mb-3 px-2">
                                  <div className="input-cust-div">
                                    <label htmlFor="degree" className="form-label">
                                      <strong>Degree</strong>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="degree"
                                      name="degree"
                                      value={degree}
                                      onChange={(e) => setDegree(e.target.value)}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-4 mb-3 px-2">
                                  <div className="input-cust-div">
                                    <label htmlFor="experience" className="form-label">
                                      <strong>Experience</strong>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="experience"
                                      name="experience"
                                      value={experience}
                                      onChange={(e) => setExperience(e.target.value)}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-4 mb-3 px-2">
                                  <div className="input-cust-div">
                                    <label htmlFor="availability" className="form-label">
                                      <strong>Availability</strong>
                                    </label>
                                    <select
                                      id="availability"
                                      name="availability"
                                      className="form-select"
                                      value={availability}
                                      onChange={(e) => setAvailability(e.target.value)}
                                    >
                                      <option value={1}>Yes</option>
                                      <option value={0}>No</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-md-4 mb-3 px-2">
                                  <div className="input-cust-div">
                                    <label htmlFor="teaches" className="form-label">
                                      <strong>Teaches</strong>
                                    </label>
                                    <select
                                      id="teaches"
                                      name="teaches"
                                      className="form-select"
                                      value={teaches}
                                      onChange={(e) => setTeaches(e.target.value)}
                                    >
                                      <option defaultValue="online">Online</option>
                                      <option value="physically">Physically</option>
                                      <option value="both">Both</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-md-8 mb-3 px-2">
                                  <div className="input-cust-div">
                                    <label htmlFor="subject_id" className="form-label">
                                      <b>Select Your Major Subject</b> <small>(required)</small>
                                    </label>
                                    <select
                                      id="subject_id"
                                      name="subject_id"
                                      className="form-select"
                                      value={subject}
                                      required
                                      onChange={(e) => setSubject(e.target.value)}
                                    >
                                      {subjects.map(subject => (
                                        <option key={subject.id} value={subject.id}>
                                          {subject.title}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>

                              </>
                            )
                        }

                        <div className="mt-4">
                          <button className="btn btn-primary text-white float-right">
                            Update Profile
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

              </div>
            </div>
          </div>

          :

          <img className="d-flex mx-auto" src={require('../../../src/assets/preloaders/loader2.gif')} width="50%" height="auto" />
      }
    </>
  );
}

export default Profile;
