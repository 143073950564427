import React from "react";
import "./navbar.css";
import { Link } from "react-router-dom";

function Navbar() {

  // get login user detail from local storage
  let user = JSON.parse(localStorage.getItem("user"));

  return (
    <>
      {/* <!-- Navbar Start --> */}
      <div className="container-fluid nav-bar bg-cust fixed-top px-0">
        <nav className="navbar navbar-expand-sm navbar-light  px-3">
          <Link to="/" className="navbar-brand d-flex align-items-center text-center">
            <div className="navbar-logo text-right">
              <img className="nav-logo" src={require('../../../src/assets/logo/logo.png')} alt="main logo" />
            </div>
          </Link>
          <button
            type="button"
            className="navbar-toggler"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav">
              {/* {user ?
                <Link to="/logout" className="nav-item nav-link mx-3">
                  Logout
                </Link>
                :
                <Link to="/login" className="nav-item nav-link mx-3">
                  Login/Signup
                </Link>
              } */}
              <Link to="/login" className="nav-item nav-link mx-3">
                Login/Signup
              </Link>
              <Link to="/" className="nav-item nav-link">
                Home
              </Link>
            </div>
          </div>
        </nav>
      </div>
      {/* <!-- Navbar End --> */}
      {/* <nav className="navbar navbar-expand-lg  fixed-top fs-5">
        <div className="container-fluid  ">
          <a className="navbar-brand" href="#">
            Teacher.com
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse p-2" id="navbarNavAltMarkup">
            <div className="navbar-nav ms-auto">
              <a className="nav-link " aria-current="page" href="#">
                Home
              </a>
              <a className="nav-link" href="#">
                About
              </a>
              <a className="nav-link" href="#">
                Profile
              </a>
            </div>
          </div>
        </div>
      </nav> */}
    </>
  );
}

export default Navbar;
