import React from "react";
import { Link } from "react-router-dom";

import "./home.css";
function Home() {
  return (
    <>
      {/* <!-- ======= Hero Section ======= --> */}
      <section id="hero" className="hero d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <h1 data-aos="fade-up" className="mb-3">Start your educational journey today.</h1>
              <p data-aos="fade-up" data-aos-delay="400">
                Welcome to Bid a Tutor, the premier online platform connecting students
                with qualified tutors. Our mission is to make education accessible to
                everyone by providing a convenient and easy-to-use platform for students
                to find the right tutor for their needs. Our website offers a vast
                network of talented tutors, each with their unique skills and
                specialties, allowing students to find the perfect match for their
                academic goals. Join the thousands of satisfied students who have found
                success with Bid a Tutor. Start your educational journey today!
              </p>
              <div data-aos="fade-up" data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <Link
                    to="/register"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Get Started</span>
                    <i className="fa fa-arrow-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 hero-img"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <img
                src="./assets/images/hero-img.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Hero --> */}

      {/* <!-- ======= Team Section ======= --> */}
      <section id="team" className="team">
        <div className="container" data-aos="fade-up">
          <header className="section-header">
            {/* <h3>Team</h3> */}
            <h1 className="mb-4">Our hard working team</h1>
          </header>

          <div className="row gy-4">
            <div
              className="col-lg-3 col-md-6 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="100"
            ></div>

            <div
              className="col-lg-3 col-md-6 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="member">
                <div className="member-img">
                  <img className="img-fluid" src={require('../../../src/assets/images/haris.jpeg')} />
                  <div className="social">
                    <a href="">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="">
                      <i className="fab fa-facebook"></i>
                    </a>
                    <a href="">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href="">
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </div>
                </div>
                <div className="member-info">
                  <h4>Muhammad Haris Naveed</h4>
                  <span>Chief Executive Officer</span>
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-6 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="member">
                <div className="member-img">
                  <img className="img-fluid" src={require('../../../src/assets/images/mohsin.jpeg')} />
                  <div className="social">
                    <a href="">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="">
                      <i className="fab fa-facebook"></i>
                    </a>
                    <a href="">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href="">
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </div>
                </div>
                <div className="member-info">
                  <h4>Mohsin Raza</h4>
                  <span>Product Manager</span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      {/* <!-- End Team Section --> */}

      {/* <!-- ======= Contact Section ======= --> */}
      <section id="contact" className="contact mb-4">
        <div className="container" data-aos="fade-up">
          <header className="section-header">
            <h2 className="mt-4 mb-4">Contact Us</h2>
          </header>

          <div className="row gy-4">
            <div className="col-lg-6">
              <div className="row gy-4">
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-geo-alt"></i>
                    <h3>Address</h3>
                    <p>
                      Islamabad, Pakistan, 44000
                    </p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-envelope"></i>
                    <h3>Email Us</h3>
                    <p>
                      bidatutor@gmail.com
                    </p>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-lg-6">
              <form action="" method="" className="php-email-form">
                <div className="row gy-4">
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Your Name"
                      required
                    />
                  </div>

                  <div className="col-md-6 ">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Your Email"
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <textarea
                      className="form-control"
                      name="message"
                      rows="6"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>

                  <div className="col-md-12 text-center">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>

                    <button type="submit">Send Message</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Contact Section --> */}
    </>
  );
}

export default Home;
