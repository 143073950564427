import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "./components/signin/SignIn";
import SignUp from "./components/Signup/SignUp";
import Navbar from "./components/navbar/Navbar";
import UserProfile from "./components/userProfile/UserProfile";
import Footer from "./components/footer/Footer";
import Profile from "./components/profile/profile";
import Sidebar from "./components/sidebar/Sidebar";
import TeacherProfile from "./components/teacherProfile/TeacherProfile";
import CreateJob from "./components/offer/offer";
import StudentJobForm from "./components/jobform/StudentJobForm";
import AcceptBid from "./components/bid/AcceptBid";
import TeacherBid from "./components/teacherbid/TeacherBid";
import JobCard from "./components/offer/offer";
import UserForm from "./components/useless/UserForm";
import StudentJobCard from "./components/studentjobcard/StudentJobCard";
import Password from "./components/password/password";
import UpdateStudentPass from "./components/updatestudentpass/UpdateStudentPass";
import SelectSubject from "./components/Sselectsubject/SelectSubject";
import Home from "./components/home/Home";
import FeedBack from "./components/feedback/FeedBack";
import FeedBackTutor from "./components/feedback/FeedBackTutor";
import ServiceForm from "./components/service/service";
import ServiceList from "./components/services/ServiceList";
import ServiceDetail from "./components/services/ServiceDetail";
import Offer from "./components/offer/offer";
import Logout from "./components/logout/logout";

function App() {
  return (
    <>
      <BrowserRouter>

        <Navbar />

        <Routes>
          <Route>
            <Route path="/" element={<Home />} />


            {/* AUTH ROUTES */}
            <Route path="/login" element={<SignIn />} />
            <Route path="/register" element={<SignUp />} />
            <Route path="/logout" element={<Logout />} />

            {/* STUDENT ROUTES */}
            <Route path="/student/profile" element={<Profile />} />
            <Route path="/student/services" element={<ServiceList />} />
            <Route path="/student/service/create" element={<ServiceForm />} />
            <Route path="/student/service/:id/edit" element={<ServiceForm />} />
            <Route path="/student/service/:id" element={<ServiceDetail />} />
            <Route path="/student/service/:id/feedback/:iid" element={<FeedBack />} />
            <Route path="/student/password/update" element={<Password />} />

            {/* TUTOR ROUTES */}
            <Route path="/tutor/profile" element={<Profile />} />
            <Route path="/tutor/services" element={<ServiceList />} />
            <Route path="/tutor/service/:id" element={<ServiceDetail />} />
            <Route path="/tutor/offers" element={<Offer />} />
            <Route path="/tutor/subjects" element={<SelectSubject />} />
            <Route path="/tutor/service/:id/feedback/:iid" element={<FeedBackTutor />} />
            <Route path="/tutor/password/update" element={<Password />} />

            {/* <Route path="/Select-Subject" element={} /> */}
            <Route path="/Security" element={<UpdateStudentPass />} />
            {/* <Route path="/Home" element={<FeedBack />} /> */}
            <Route path="/New-Post" element={<JobCard />} />
            <Route path="/Create_student_post" element={<StudentJobForm />} />
            <Route path="/teacher-profile" element={<TeacherProfile />} />
            <Route path="/Bids" element={<AcceptBid />} />
            <Route path="/bidding" element={<TeacherBid />} />
            <Route path="/student/services" element={<StudentJobCard />} />
          </Route>
        </Routes>
      </BrowserRouter>
      {/* <Navbar /> */}

      <Footer />
    </>
  );
}

export default App;
