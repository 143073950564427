import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

import "./offer.css";
import Sidebar from "../sidebar/Sidebar";
import { Link } from "react-router-dom";
import { api_url } from "../../config";


function Offer() {

  // USE USE-STATE HOOKS TO MANAGE PAGE CONTENT
  const [preloader, setPreloader] = useState(true);
  const [title, setTitle] = useState(" ");
  const [offers, setOffers] = useState([]);

  const location = useLocation();
  const path = location.pathname;
  // console.log(path);

  // get token from local storage
  const token = localStorage.getItem("token");

  useEffect(() => {
    getTutorOffers();

    if (path == "/student/services") {
      setTitle("Your Services")
    } else {
      setTitle("Your Offers")
    }

  }, [])

  // get tutor offers
  async function getTutorOffers(e) {
    let url = api_url + "user/offers";

    const response = await axios.get(
      url,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }
    );

    // console.log(response);
    if (response && response.status == 200) {

      setPreloader(false);

      let offers = response.data.offers;
      setOffers(offers);
      // console.log(offers);
    }
    else {

    }

  }

  return (
    <>

      {
        !preloader ?

          <div className="container py-5">
            <div className="row">

              <div className="col-md-3">
                <Sidebar title="offers" />
              </div>

              <div className="col-md-9">
                <div className="row g-0">
                  <h3 className="card-heading">{title}</h3>

                  {offers.map((offer) => {
                    return (

                      <div className="col-md-12 px-0" key={offer.id}>
                        <div className="card mb-3">

                          <div className="card-header pb-2 border-0 offer-card-bg">

                            <div className="row">
                              <div className="col-md-10">
                                <h6 className="text-muted">SERVICE</h6>
                                <h3 className="card-title mb-0 text-primary-cust"><strong>{offer.service.title}</strong></h3>
                                <h4 className="card-title">Budget: {offer.service.budget} PKR</h4>
                              </div>
                              <div className="col-md-2">
                                <br />
                                {
                                  offer.is_accepted == 1 && offer.service.status == "completed" ?
                                    <>
                                      <Link to={'/tutor/service/' + offer.service.id + '/feedback/' + offer.id} >
                                        <strong className="offer-status text-white">Feedback</strong>
                                      </Link>
                                      {/* <Link to={'/student/service/' + offer.service.id + '/feedback/' + applicant.id} >
                                        <strong className="offer-status text-white">Feedback</strong>
                                      </Link> */}
                                    </>
                                    :
                                    <>
                                    </>
                                }
                              </div>
                            </div>
                          </div>

                          <div className="card-body">
                            <p>{offer.service.description}</p>
                            <div className="row mb-3">
                              <div className="col-3">
                                <p className="mb-0">Subject</p>
                                <b>{offer.service.subject.title}</b>
                              </div>
                              <div className="col-3">
                                <p className="mb-0">Want To Read</p>
                                <b> {offer.service.want_to_learn}</b>
                              </div>
                              <div className="col-3">
                                <p className="mb-0">Status</p>
                                <b> {offer.service.status}</b>
                              </div>
                            </div>

                            <p className="card-text">
                              <small className="text-body-secondary">
                                <i className="fa fa-user p-2"></i>
                                <b>
                                  {offer.service.student.name}
                                </b>
                              </small>
                              <i className="fa fa-globe p-2 ml-5"></i>
                              <small className="text-body-secondary">{offer.service.student.city.name}</small><br />
                              <small className="text-body-secondary">{offer.service.student.complete_address}</small>
                            </p>
                          </div>

                          <div className="card-footer border-0 offer-card-bg">
                            <h5 className="card-title"><strong>Your Offer For This Service</strong>
                              {(() => {
                                if (!offer.is_accepted) {
                                  return (
                                    <strong className="offer-status">Pending</strong>
                                  )
                                } else if (offer.is_accepted == 1) {
                                  return (
                                    <strong className="offer-status">Accepted</strong>
                                  )
                                } else {
                                  return (
                                    <strong className="offer-status">Rejected</strong>
                                  )
                                }
                              })()}
                            </h5>
                            <h5 className="card-title mb-0"><strong>{offer.offer} PKR</strong></h5>
                            <p className="card-text">{offer.comment}</p>
                          </div>

                        </div>
                      </div>
                    );
                  })}

                </div>
              </div>
            </div>
          </div>
          :

          <img className="d-flex mx-auto" src={require('../../../src/assets/preloaders/loader2.gif')} width="50%" height="auto" />
      }
    </>
  );
}

export default Offer;
