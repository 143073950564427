import React, { useState } from "react";
import axios from "axios";
import "./password.css";
import { api_url } from "../../config";
import Sidebar from "../sidebar/Sidebar";


// CREATE SERVICE COMPONENT
function Password() {

  // USE-STATE HOOKS FOR MANAGING PASSWORD COMPONENT
  const [action, setAction] = useState(" ");
  const [serverError, setServerError] = useState(false);
  const [preloader, setPreloader] = useState(false);
  const [validationErrors, setValidationErros] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);


  // PASSWORD IPUTS
  const [oldPassword, setOldPassword] = useState(" ");
  const [newPassword, setNewPassword] = useState(" ");


  /*
  |========================================================
  | UPDATE OLD PASSWORD -- API CALLING
  |========================================================
  */
  async function updatePassword(e) {
    e.preventDefault();

    setPreloader(true);

    // get token from local storage
    const token = localStorage.getItem("token");
    // console.log(token);

    // MAKE FORMDATA FOR CALLING STORE NEW SERVER API
    const formData = new FormData();
    formData.append("old_password", oldPassword);
    formData.append("new_password", newPassword);
    const body = formData;

    //  END-POINT
    let url = api_url + "user/profile/password/update";

    const response = await axios.post(
      url,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }
    );

    // console.log(response);
    if (response && response.status == 200) {
      setPreloader(false);

      let data = response.data;
      if (data.status == 200) {

        let message = data.message;
        setMessage(message);
        setSuccessMessage(true);

      } else {
        let errors = data.errors;
        if (typeof (errors) == "string") {
          errors = [errors];
        }
        // console.log(errors);
        setErrors(errors);
        setValidationErros(true);
      }

    } else {
      console.log("API CALLING FAILED");
    }


  }
  return (
    <>
      <div className="container py-5">
        <div className="row">

          <div className="col-md-3">
            <Sidebar title="password" />
          </div>

          <div className="col-md-9 px-0">

            <h3 className="card-heading">Update Password</h3>

            <div className="card">
              <div className="card-body p-4">

                {
                  validationErrors ?
                    <div className="alert alert-danger alert-dismissible" role="alert">
                      <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                      <ul className="mb-0">
                        {errors.map((error) => {
                          return (
                            <li key={error.id} className='list'>
                              <span className='repo-text'>{error} </span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    :
                    <></>
                }
                {
                  message ?
                    <div className="alert alert-success alert-dismissible" role="alert">
                      <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                      <h6 className="mb-0">{message}</h6>
                    </div>
                    :
                    <></>
                }

                <form onSubmit={updatePassword}>
                  <div className="row">

                    <div className="col-md-7 mb-3 px-2 mx-auto">
                      <div className="input-cust-div">
                        <label htmlFor="old_password" className="form-label">
                          <b>Old Password</b> <small>(required)</small>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="old_password"
                          name="old_password"
                          required
                          onChange={(e) => setOldPassword(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-7 mb-3 px-2 mx-auto">
                      <div className="input-cust-div">
                        <label htmlFor="new_password" className="form-label">
                          <b>New Password</b> <small>(required)</small>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="new_password"
                          name="new_password"
                          required
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="mt-4">
                      <button className="btn btn-primary text-white float-right">
                        Update Password
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default Password;
