import React, { useState, useEffect } from "react";
import axios from "axios";

import $ from "jquery";
import "./selectsubject.css";
import { Link } from "react-router-dom";
import { api_url } from "../../config";
import Sidebar from "../sidebar/Sidebar";


function SelectSubject() {

  const [preloader, setPreloader] = useState(true);
  const [subjects, setSubjects] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    getTutorSubjects();
  }, [])

  // get tutor subjects
  async function getTutorSubjects(e) {

    let url = api_url + "user/subjects";

    const response = await axios.get(
      url,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }
    );

    // console.log(response);
    if (response && response.status == 200) {

      setPreloader(false);
      let subjects = response.data.tutor_with_subjects.tutor.subjects;
      setSubjects(subjects);
      // console.log(subjects);
    }
    else {
      // console.log("API CALLING FAILED");
    }

  }

  return (
    <>
      {
        !preloader ?
          <div className="container py-5">
            <div className="row">

              <div className="col-md-3">
                <Sidebar title="subjects" />
              </div>


              <div className="col-md-9">
                <div className="row">

                  <h3 className="card-heading">Your Subjects</h3>

                  <div className="table-responsive">
                    <table className="table">
                      <tbody>

                        {subjects.map((subject) => {
                          return (
                            <tr>
                              <td>{subject.title}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            </div>
          </div>
          :

          <img className="d-flex mx-auto" src={require('../../../src/assets/preloaders/loader2.gif')} width="50%" height="auto" />
      }
    </>
  );
}

export default SelectSubject;
